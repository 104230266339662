<!-- 续保管理 -->
<template>
  <div class="renewal-insurance-container">
    <van-tabs
      class="nav-tabs"
      v-model="activeTab"
      @change="onTabChange"
      swipe-threshold="6"
      color="#FF8F2B"
      line-height="4"
      sticky
      title-active-color="#FF8F2B"
    >
      <!-- <van-tab title="全部" name="0"> </van-tab> -->
      <van-tab title="待续保" name="1"> </van-tab>
      <van-tab title="已续保" name="2"> </van-tab>
      <van-tab title="已过期" name="3"> </van-tab>
    </van-tabs>
    <div class="month-order-numer">近一个月待续保订单（{{ totalNum }}）</div>

    <van-pull-refresh style="min-height: 100vh" v-model="refreshing" @refresh="onRefresh">
      <van-list
        v-model="loading"
        :finished="finished"
        :error.sync="error"
        error-text="请求失败，点击重新加载"
        finished-text="没有更多了"
        @load="onLoad"
        :immediate-check="false"
      >
        <template v-if="orderList.length > 0">
          <div class="order-item" v-for="(item, index) in orderList" :key="index">
            <div @click="toDetail(item.id)">
              <p class="title">
                <span class="title-txt van-ellipsis">{{ item.productName }}</span>
                <!-- guarantee  surrender-->
                <span v-if="item.newStatus == 1" class="status-txt orange-color">待支付</span>
                <span v-else-if="item.newStatus == 2" class="status-txt orange-color">已支付</span>
                <span v-else-if="item.newStatus == 3" class="status-txt guarantee-color">保障中</span>
                <span v-else-if="item.newStatus == 4" class="status-txt surrender-color">已退保</span>
                <span v-else-if="item.newStatus == 5" class="status-txt surrender-color">已失效</span>
                <template v-else-if="item.newStatus == 6">
                  <span class="status-txt surrender-color">{{ item.statusName }}</span>
                </template>
                <template v-else-if="item.newStatus == 0">
                  <span v-if="item.statusName == '已撤销'" class="status-txt surrender-color">{{ item.statusName }}</span>
                  <span v-else class="status-txt orange-color">{{ item.statusName }}</span>
                </template>
              </p>
              <p class="time">投保时间：{{ item.insuredTime }}</p>
              <div class="content">
                <p class="name">
                  <span class="label">投/被保人：</span>
                  <span class="value">{{ item.appntName }}/{{ item.insuredName }}</span>
                </p>
                <p class="ins-period" v-if="item.insStartTime && item.insEndTime">
                  <span class="label"> 保险期间：</span>
                  <span class="value">{{ item.insStartTime ? item.insStartTime : "--" }} 至 {{ item.insEndTime ? item.insEndTime : "--" }}</span>
                </p>
                <p class="premium">
                  <span class="label"> 保费：</span>
                  <span class="value">{{ item.premium }}元 </span>
                </p>
                <!-- 已退保 item.newStatus == 4 &&  -->
                <div v-if="item.surrender.surrenderPremium" class="underwriting-desc gray-gradient">
                  # 已退保：退保金额{{ item.surrender.surrenderPremium }}元
                </div>
                <!-- 未支付 -->
                <div v-if="item.manual.manualResult" class="underwriting-desc orange-gradient">
                  <p class="van-ellipsis"># {{ item.manual.manualResult }}</p>
                  <van-icon
                    v-if="item.manual.manualResult.length > 19"
                    name="question-o"
                    class="question"
                    @click.stop="showmanualResult(item.manual.manualResult)"
                  />
                </div>
                <img
                  v-if="item.newStatus == 4 && item.surrender.surrenderPremium"
                  class="seal"
                  src="../../assets/image/guarantee-gray-seal.png"
                  alt=""
                />
                <img v-if="item.newStatus == 3" class="seal" src="../../assets/image/guaranteeing-seal.png" alt="" />
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <van-empty></van-empty>
        </template>
      </van-list>
    </van-pull-refresh>
    <van-overlay class="overlay-loading" :show="dataLoading" @click="dataLoading = false">
      <div class="overlay-wrapper" @click.stop>
        <van-loading size="24" />
        <p style="color: #fff; margin-left: 0.5em">加载中...</p>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import { getOrderList } from "../../api/api"
export default {
  data() {
    return {
      activeTab: 0,
      refreshing: false,
      loading: false,
      error: false,
      finished: false,
      orderList: [],
      page: 1,
      perPage: 10,
      dataLoading: false,
      totalNum: 0,
    }
  },
  created() {
    this.onLoad()
  },
  methods: {
    // async getNavTab() {
    //   const res = await getNavTab({ type: 1 })
    //   this.navList = res.data.list
    // },
    onTabChange() {
      this.page = 1
      this.orderList = []
      this.finished = false
      this.refreshing = false
      this.loading = true
      console.log("tabchange")
      this.onLoad()
    },
    async onLoad() {
      if (this.refreshing) {
        this.orderList = []
        this.refreshing = false
      }

      this.dataLoading = true
      let res = await getOrderList({
        page: this.page,
        perPage: this.perPage,
        renew: this.activeTab,
        status: 3,
      })
      this.page++
      this.dataLoading = false
      this.loading = false
      this.totalNum = res.data.totalNum
      this.orderList = this.orderList.concat(res.data.list)
      if (this.orderList.length >= res.data.totalNum) {
        this.finished = true
      }
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false
      this.page = 1
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true
      this.onLoad()
    },
    toDetail(id) {
      this.$router.push(`/orderDetail?id=${id}&source=${this.$route.path}`)
    },
  },
}
</script>
<style lang="less" scoped>
.renewal-insurance-container {
  padding-top: 92px;
  background-color: #fbfbfb;
  width: 100%;
  height: 100%;
  position: relative;

  .surrender-color {
    color: #98999a;
  }
  .guarantee-color {
    color: #5ba5fd;
  }
  .orange-color {
    color: #fe8a3d;
  }
  /deep/.nav-tabs {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100;
    .van-tabs__wrap {
      height: 92px;
    }
    .van-tab {
      height: 92px;
      padding: 29px 20px 0px;
      align-items: stretch;
      font-size: 34px;
      line-height: 48px;
      color: #333;
    }
  }
  .month-order-numer {
    padding: 24px 24px 0;
    font-size: 24px;
    color: #777;
  }
  /deep/.van-pull-refresh {
    background-color: #fbfbfb;
    padding: 0 24px;
    height: calc(100vh - 92px) !important;
    overflow: auto !important;
  }

  /deep/.van-list {
    height: auto !important;
    .order-item {
      width: 702px;
      padding: 30px 24px;
      font-size: 30px;
      background-color: #fff;
      border-radius: 20px;
      &:first-child {
        margin-top: 15px;
      }
      &:nth-child(n + 2) {
        box-shadow: 0px 4px 77px 0px rgba(0, 0, 0, 0.08);
        margin-top: 30px;
      }
      .title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .title-txt {
          width: 374px;
          height: 48px;
          font-size: 34px;
          font-weight: 500;
          color: #333333;
          line-height: 48px;
        }
        .status-txt {
          height: 40px;
          font-size: 28px;

          line-height: 40px;
        }
      }
      .time {
        height: 37px;
        margin-top: 4px;
        font-size: 26px;
        color: #777777;
        line-height: 37px;
      }
      .content {
        position: relative;
        overflow: hidden;
        width: 654px;
        margin-top: 20px;
        padding: 24px;
        background-color: #fafafa;
        border-radius: 20px;

        .label {
          color: #777;
        }
        .value {
          color: #333;
        }
        p {
          height: 42px;
          line-height: 42px;
          &:nth-child(n + 2) {
            margin-top: 12px;
          }
        }
        .seal {
          position: absolute;
          top: -14px;
          right: -14px;
          width: 100px;
          height: 100px;
        }
        .gray-gradient {
          color: #777;
          background: linear-gradient(264deg, rgba(247, 247, 247, 0) 0%, #ededed 100%);
        }
        .orange-gradient {
          color: #fe8a3d;
          background: linear-gradient(264deg, rgba(255, 247, 242, 0) 0%, #fff3e8 100%);
        }
        .underwriting-desc {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 600px;
          height: 60px;
          margin-top: 20px;
          padding: 10px 0 10px 16px;
          font-size: 28px;
          border-radius: 10px;
          p {
            width: 520px;
          }
          .question {
            margin-right: 10px;
          }
        }
      }
      .btn-group {
        display: flex;
        align-items: center;
        margin-top: 20px;
        .more {
          color: #777777;
          height: 37px;
          font-size: 26px;
          color: #777777;
          line-height: 37px;
        }
        .right {
          margin-left: auto;
          display: flex;
          align-items: center;
        }
        .btn {
          min-width: 168px;
          padding: 0 24px;
          height: 68px;
          margin-left: 16px;
          border-radius: 34px;
          border: 1px solid #cecfd0;
          text-align: center;
          line-height: 68px;
          font-size: 26px;
          color: #333333;
          background-color: #fff;
        }
        .orange-btn {
          border-color: #fe8a3d;
          color: #fe8a3d;
        }
      }
    }
  }

  .overlay-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    p {
      font-size: 32px;
    }
  }
}
</style>
